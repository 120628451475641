<template>
	<div class="header">
		<div class="l-content">
			<el-button size="small" class="bbuu" @click="handCollapse">
				<el-icon :size="20">
					<Menu />
				</el-icon>
			</el-button>
			<div>{{ userInfo.corp }}</div>
			<el-dropdown trigger="click">
				<el-button type="primary" size="small" icon="Switch" style="margin: 0 0 0 10px">切换</el-button>
				<template #dropdown>
					<el-dropdown-menu>
						<el-dropdown-item v-for="(item, index) in linkArr" :key="index" @click="linkS(item)">
							{{ item.name }}
						</el-dropdown-item>
					</el-dropdown-menu>
				</template>
			</el-dropdown>
		</div>
		<div class="r-content">
			<el-dropdown>
				<span class="el-dropdown-link">
					<img v-if="!userInfo.userid" class="user" src="@/assets/images/user.png" alt="" />
					<template v-else>
						<img v-if="userInfo.sex == '0'" class="user" src="@/assets/images/girl.png" alt="" />
						<img v-if="userInfo.sex == '1'" class="user" src="@/assets/images/boy.png" alt="" />
					</template>
					<div class="username">{{ userInfo.name }}</div>
				</span>
				<template #dropdown>
					<el-dropdown-menu>
						<el-dropdown-item @click="remove()" class="tan">退&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;出</el-dropdown-item>
					</el-dropdown-menu>
				</template>
			</el-dropdown>
		</div>
	</div>
</template>
<script>
import { getAction, postAction } from "@/api/manage";
import { ElMessage } from "element-plus";
import store from "@/store";
export default {
	data() {
		return {
			linkArr: [
				{
					name: "数据加载中...",
					url: "",
				},
			],
			userInfo: {
				name: "用户",
				corp: "管理平台",
			},
		};
	},
	created() {
		this.getLink();
		this.getUserInfo();
	},
	methods: {
		linkS(item) {
			if (!item.url) {
				ElMessage.error(item.name);
				return;
			}
			window.location.href = `${item.url}#/linkLog?jq=${localStorage.getItem("token")}`;
		},
		handCollapse() {
			store.commit("updateIsCollapse");
		},
		remove() {
			localStorage.clear();
			store.dispatch("resetState");
			this.$router.push({ path: "/login" });
		},
		getLink() {
			if (localStorage.getItem("linkArr")) {
				this.linkArr = [...JSON.parse(localStorage.getItem("linkArr"))];
				return;
			}
			postAction("getSubsystemUrl", {
				subsystem: "jc", // 不同项目更改
			}).then((res) => {
				if (res.code == 200) {
					localStorage.setItem("linkArr", JSON.stringify(res.data));
					this.linkArr = [...res.data];
				} else {
					ElMessage.error(res.message || res.msg);
					this.linkArr[0].name = res.message || res.msg;
				}
			});
		},
		getUserInfo() {
			if (localStorage.getItem("userInfo")) {
				this.userInfo = { ...JSON.parse(localStorage.getItem("userInfo")) };
				return;
			}
			getAction("jc/user/myinfo").then((res) => {
				if (res.code == 200) {
					localStorage.setItem("userInfo", JSON.stringify(res.data));
					this.userInfo = { ...res.data };
				} else {
					ElMessage.error(res.message || res.msg);
					this.userInfo.name = "未成功获取用户信息";
				}
			});
		},
	},
};
</script>
<style lang="less" scoped>
.header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: #fff;
	color: #000;
	overflow: hidden;
}

.r-content {
	.user {
		width: 24px;
		width: 24px;
		border-radius: 50%;
		margin-right: 8px;
	}
}

.l-content {
	display: flex;
	align-items: center;
}

h3 {
	color: #fff;
	margin-left: 10px;
}

.bbuu {
	margin: 10px;
}
.bread :deep(span) {
	color: #fff !important;
	cursor: pointer !important;
}
.el-dropdown-link {
	margin-right: 20px;
	display: flex;
	align-items: center;
	color: #fff;
}
.el-dropdown-link:focus-visible {
	outline: unset;
}
.username {
	font-family: MicrosoftYaHei;
	font-size: 14px;
	color: #000000;
	line-height: 22px;
	text-align: left;
	font-style: normal;
	cursor: pointer;
}
</style>
