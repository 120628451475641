import { createApp } from "vue";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import App from "./App.vue";
import router from "./router";
import "./api/mock.js";
import * as ElementPlusIconsVue from "@element-plus/icons-vue";
import store from "./store";
import zhCn from "element-plus/es/locale/lang/zh-cn";
const app = createApp(App);
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
	app.component(key, component);
}

import mTable from "@/components/mTable.vue";
import cutBox from "@/components/cutBox.vue";
app.component("mTable", mTable);
app.component("cutBox", cutBox);

app.use(router).use(store);
app.use(ElementPlus, { locale: zhCn });
app.mount("#app");


//解决开发模式下报下面错误问题
//Uncaught ResizeObserver loop completed with undelivered notifications.
const debounce = (fn, delay) => {
	let timer = null;
	return function () {
	  let context = this;
	  let args = arguments;
	  clearTimeout(timer);
	  timer = setTimeout(function () {
		fn.apply(context, args);
	  }, delay);
	}
  }
  const _ResizeObserver = window.ResizeObserver;
  window.ResizeObserver = class ResizeObserver extends _ResizeObserver{
	constructor(callback) {
	  callback = debounce(callback, 16);
	  super(callback);
	}
  }
   