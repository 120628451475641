import { ElNotification } from "element-plus";
import axios from "axios";
import store from "@/store";
import router from "@/router";
const service = axios.create({
	// baseURL: "http://192.168.39.141:8001/",
	baseURL: "https://v17test-api.hello-world.cool/",
	timeout: 30000, // 请求超时时间
});
let node = null;
const err = (error) => {
	return Promise.reject(error);
};

const res = (response) => {
	if (response.data) {
		if (response.data.code == 1002) {
			if (node) {
				node.close();
			}
			node = ElNotification({
				title: "系统提示",
				message: response.data.message,
				type: "error",
				duration: 3000,
			});
			localStorage.clear();
			store.dispatch("resetState");
			router.push({ path: "/login" });
			return Promise.reject(response);
		}
	}
	return response.data;
};

// request interceptor
service.interceptors.request.use(
	(config) => {
		const token = localStorage.getItem("token");
		if (token) {
			config.headers["Authorization"] = token;
		}
		return config;
	},
	(error) => {
		return Promise.reject(error);
	}
);

// response interceptor
service.interceptors.response.use(res, err);

export { service as axios };
