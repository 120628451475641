<template>
	<router-view />
</template>

<style lang="less">
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
	box-sizing: border-box;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
	display: block;
}

body {
	line-height: 1;
}

ol,
ul {
	list-style: none;
}

blockquote,
q {
	quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
	content: "";
	content: none;
}

a,
a:hover {
	color: inherit;
	text-decoration: none;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}

html,
body {
	width: 100%;
	height: 100%;
	background-color: #f5f5f5;
	font-family: "PingFangSC-Light", "PingFang SC", "STHeitiSC-Light", "Helvetica-Light", "Arial", "sans-serif";
}

// 公共样式
.fl {
	float: left;
}

.fr {
	float: right;

	.button-group-item {
		padding-left: 3px;
	}
}

//清除浮动
.clearfix {
	zoom: 1;

	&:after {
		display: block;
		clear: both;
		content: "";
		visibility: hidden;
		height: 0;
	}
}
</style>

